<div class="lightbox-gallery-container" [class.fullscreen]="isFullScreen">
  <div class="gallery">
    <ng-container *ngFor="let item of galleryData; let i = index;">
        <!-- <div class="gallery-item" [ngStyle]="{'width': slidesToShow ? ((100 - (slidesToShow * 1)) / slidesToShow + '%' ) : '', 'flex': slidesToShow ? ('0 0 ' + (100 - (slidesToShow * 1)) / slidesToShow + '%' ) : ''}" (click)="previewGalleryItem(i)"> -->
        <div class="gallery-item" (click)="previewGalleryItem(i)">
        <div class="gallery-item-box">
          <ng-container *ngIf="item.type === 'image'">
            <img [src]="item.url" [alt]="item.fileName" class="img-fluid" loading="lazy"/>
          </ng-container>
          <ng-container *ngIf="item.type === 'video'">
            <video preload="auto" controlsList="nodownload nofullscreen noremoteplayback">
              <source [src]="item.url" type="video/{{item.ext}}" />
              Your browser does not support the video tag.
            </video>
          </ng-container>
          <ng-container *ngIf="item.type === 'pdf'">
            <span class="mailbox-attachment-icon has-img clearfix">
              <i class="pdf-icon medium-red"></i>
            </span>
          </ng-container>
        </div>
        <div class="gallery-item-desc">
          <span>{{item.fileName}}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div #lightboxGallery class="_lightbox" *ngIf="showMask" animation-leave>
    <span *ngIf="showCount" class="count text-white">{{(currentIndex + 1) + '/' + totalItemsCount}}</span>
    <div class="right-toolbar">
      <button *ngIf="!isFullScreen" class="full-screen" title="Enter Full Screen" (click)="openFullscreen()" name="full-screen">
        <svg width="20px" height="20px" viewBox="0 0 18 18"><path class="fslightbox-svg-path" d="M4.5 11H3v4h4v-1.5H4.5V11zM3 7h1.5V4.5H7V3H3v4zm10.5 6.5H11V15h4v-4h-1.5v2.5zM11 3v1.5h2.5V7H15V3h-4z"></path></svg>
      </button>
      <button *ngIf="isFullScreen" class="full-screen" title="Exit Full Screen" (click)="closeFullscreen()" name="exit-fullscreen">
        <svg  width="24px" height="24px" viewBox="0 0 950 1024"><path class="fslightbox-svg-path" d="M682 342h128v84h-212v-212h84v128zM598 810v-212h212v84h-128v128h-84zM342 342v-128h84v212h-212v-84h128zM214 682v-84h212v212h-84v-128h-128z"></path></svg>
      </button>
      <button class="close-btn text-white" (click)="onClosePreview()" title="Close" name="close-screen">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <button *ngIf="controls" class="btn-lighbox-carousel btn-prev text-white" (click)="onPrev()" title="Prev" name="prev-btn">
      <i class="fas fa-arrow-left"></i>
    </button>
    <button *ngIf="controls" class="btn-lighbox-carousel btn-next text-white" (click)="onNext()" title="Next" name="next-btn">
      <i class="fas fa-arrow-right"></i>
    </button>
    <div class="lighbox-item" *ngIf="previewVisible"
    [@animation]="{value: 'visible'}"
    (@animation.done)="onAnimationEnd($event)"
    >
      <img *ngIf="currentLightboxItem.type === 'image'"
       [src]="currentLightboxItem.url" [alt]="currentLightboxItem.fileName" loading="lazy" />
       <ng-container *ngIf="currentLightboxItem.type === 'video'">
          <video #videoEle controls autoplay>
            <source [src]="currentLightboxItem.url" type="video/{{currentLightboxItem.ext}}" />
            Your browser does not support the video tag.
          </video>
        </ng-container>
          <ngx-doc-viewer *ngIf="currentLightboxItem.type === 'pdf'" [url]= "currentLightboxItem.url" viewer ="google" style="width:100%;height:90%;"></ngx-doc-viewer>
    </div>
  </div>
</div>
