import { Component, OnInit, Input, Inject, HostListener, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import { trigger, transition, style, animate, AnimationEvent } from '@angular/animations';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

interface Item {
  type: string;
  url: string;
  fileName: string;
  filePath: string;
  ext?: string;
}

@Component({
    selector: 'app-lightbox-gallery',
    templateUrl: './lightbox-gallery.component.html',
    styleUrls: ['./lightbox-gallery.component.scss'],
    animations: [
        trigger('animation', [
            transition('void => visible', [
                style({ transform: 'scale(0.5)' }),
                animate('150ms', style({ transform: 'scale(1)' }))
            ]),
            transition('visible => void', [
                style({ transform: 'scale(1)' }),
                animate('150ms', style({ transform: 'scale(0.5)' }))
            ])
        ]),
        trigger('animation-leave', [
            transition(':leave', [
                style({ opacity: 1 }),
                animate('50ms', style({ opacity: 0.8 }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgFor, NgIf, NgxDocViewerModule]
})
export class LightboxGalleryComponent implements OnInit, OnChanges {

  @Input() galleryData: Item[] = [];
  @Input() slidesToShow: number;
  @Input() showCount: boolean = false;

  previewVisible = false;
  showMask = false;
  currentIndex = 0;
  currentLightboxItem: Item = this.galleryData[0];
  controls = true;
  totalItemsCount = 0;
  elem: any;
  isFullScreen: boolean;
  @ViewChild('videoEle') videoElement: ElementRef;
  constructor(@Inject(DOCUMENT) private document: any) {}

  ngOnInit(){
    this.totalItemsCount = this.galleryData.length;
    this.chkScreenMode();
    this.elem = document.documentElement;
  }

  ngOnChanges(){
    this.totalItemsCount = this.galleryData.length;
  }

  previewGalleryItem(index: number) {
    this.showMask = true;
    this.previewVisible = true;
    this.currentIndex = index;
    this.currentLightboxItem = this.galleryData[index];
  }

  onAnimationEnd(event: AnimationEvent) {
    if(event.toState === 'void') {
      this.showMask = false;
    }
  }

  onClosePreview(){
    this.previewVisible = false;
  }

  onPrev(){
    this.currentIndex = this.currentIndex - 1;
    if(this.currentIndex < 0) {
      this.currentIndex = this.galleryData.length - 1;
    }
    this.currentLightboxItem = this.galleryData[this.currentIndex];
    if(this.videoElement && this.currentLightboxItem.type === 'video') {
      let _video: HTMLVideoElement = this.videoElement.nativeElement;
      _video.load();
      _video.play();
    }
  }

  onNext() {
    this.currentIndex = this.currentIndex + 1;
    if(this.currentIndex > this.galleryData.length - 1) {
      this.currentIndex = 0;
    }
    this.currentLightboxItem = this.galleryData[this.currentIndex];
    if(this.videoElement && this.currentLightboxItem.type === 'video') {
      let _video: HTMLVideoElement = this.videoElement.nativeElement;
      _video.load();
      _video.play();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

  fullscreenmodes(event){
    this.chkScreenMode();
  }

  chkScreenMode(){
    if(this.document.fullscreenElement){
      this.isFullScreen = true;
    }else{
      this.isFullScreen = false;
    }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      this.document.msExitFullscreen();
    }
  }

}
